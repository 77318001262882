<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="地址" prop="address" style="width: 25%">
              <a-input v-model.trim="queryParams.address" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="项目名称" prop="monitorpointname" style="width: 25%">
              <a-input v-model.trim="queryParams.monitorpointname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="width: 20%;float: right">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
              <a-form-model-item label="项目ID" prop="monitorpointnum" style="width: 25%">
                <a-input v-model.trim="queryParams.monitorpointnum" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item label="区域" prop="areacode" style="width: 25%">
                <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="queryAreaSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true"></a-cascader>
              </a-form-model-item>
              <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepname" style="width: 25%">
                <a-input v-model="queryParams.userdepname" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item label="是否绑定终端" prop="linkclient" style="width: 25%">
                <a-select v-model.trim="queryParams.linkclient" placeholder="请输入">
                  <a-select-option :value="1">是</a-select-option>
                  <a-select-option :value="0">否</a-select-option>
                  <a-select-option :value="2">全部</a-select-option>
                </a-select>
              </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">项目列表</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="deviceinfoid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="onlineorder" slot-scope="value">
            <a-tag :color="value==4?'green':value==0?'red':value==1?'':''">{{value==4?'在线':value==0?'离线':value==1?'无设备':value}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-button type="link" size="small" @click="showModal(value, record)">配置</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="modalVisible" title="项目参数配置" centered :width="750">
      <template slot="footer">
        <a-button @click="modalVisible=false">取消</a-button>
        <a-button type="primary" @click="confirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formData" :label-col="{span:11}" :wrapper-col="{span:13}">
        <a-form-model-item label="项目工程调度员需要确警的系统（场景）" prop="confirmSystemList">
          <a-select mode="multiple" v-model="confirmSystemList" style="width: 300px">
            <a-select-option v-for="(item, index) in systemList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="项目工程调度员需要查看的系统（场景）" prop="examineSystemList">
          <a-select mode="multiple" v-model="examineSystemList" style="width: 300px">
            <a-select-option v-for="(item, index) in systemList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="消防报警时间窗内不重复上报使能" prop="alarmbyvoice">
          <a-select v-model="formData.alarmbyvoice" style="width: 300px">
            <a-select-option value="使能">使能</a-select-option>
            <a-select-option value="禁用">禁用</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="声音检测窗口时长设置" prop="voiceduration">
          <a-input v-model="formData.voiceduration" style="width: 300px" suffix="秒"></a-input>
        </a-form-model-item>
        <a-form-model-item label="异常记录中报事报修工单上报使能" prop="reportsmatter">
          <a-select v-model="formData.reportsmatter" style="width: 300px">
            <a-select-option value="使能">使能</a-select-option>
            <a-select-option value="禁用">禁用</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import pagination from '@/mixins/pagination'
import {getTimeRange} from 'U'
import {mapState} from "vuex";
import areaselect from "@/mixins/areaselect";
import deptselect from "@/mixins/deptselect";
import {getMonitorPointinfoById, getMonitorPointListByCondition, modifyMonitorPointSystemRole} from "A/monitoring";
import {getDictByDicType} from "A/xtpz";
export default {
  name:'monitorpointparam',
  mixins: [areaselect, deptselect, pagination],
  data() {
    return {
      showAdvanced: false,
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      queryParams: {
        monitorpointnum: '',
        address: '',
        areacode: '',
        monitornum:'',
        userdepname:'',
        devicenum:'',
        userdepid:'',
        userdepsid:'',
        linkclient: 1,
      },
      tableColumns: [
        {
          title: '项目ID',
          dataIndex: 'monitorpointnum',
          key: 'monitorpointnum',
          ellipsis: true,
          width: 165
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '使用单位',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '在线/离线',
          dataIndex: 'onlineorder',
          key: 'onlineorder',
          ellipsis: true,
          width: 80,
          scopedSlots: {customRender: 'onlineorder'}
        },
        {
          title: '',
          key: 'monitoring',
          width: 100,
          scopedSlots: { customRender: 'monitoring' }
        },
        {
          title: '操作',
          key: 'operation',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      formData:{
        monitorpointid:'',
        confirmsystem:'',
        examinesystem:'',
        alarmbyvoice:'',
        voiceduration:'',
        reportsmatter:'',
      },
      systemList:[],
      confirmSystemList:[],
      examineSystemList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '项目参数';
    },
    breadcrumb() {
      return [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '高级设置',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch:{
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.installstarttime = start;
      this.queryParams.installendtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    'confirmSystemList'(val) {
      if (val && val.length) {
        this.formData.confirmsystem = val.join(',')
        this.$refs.modalForm.clearValidate('confirmsystem');
      }else {
        this.formData.confirmsystem = []
      }
    },
    'examineSystemList'(val) {
      if (val && val.length) {
        this.formData.examinesystem = val.join(',')
        this.$refs.modalForm.clearValidate('examinesystem');
      }else {
        this.formData.examinesystem = []
      }
    },
  },
  created() {
    this.initDeptOptionsAll();
    this.getDictType();
    this.getTableData();
  },
  methods: {
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = [];
      this.userdepidCascaderSelected = [];
      this.confirmSystemList=[];
      this.examineSystemList=[];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMonitorPointListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getDictType(){
      getDictByDicType({dictype:23}).then(res=>{
        this.systemList=res.item
      })
    },
    showModal(value,record){
      this.formData.monitorpointid=record.monitorpointid
      getMonitorPointinfoById({monitorpointid:record.monitorpointid}).then(res=>{
        if(res&&res.returncode==='0'){
          this.confirmSystemList = res.item.confirmSystemList ? res.item.confirmSystemList.map(item => item.dickey) : [];
          this.examineSystemList = res.item.examineSystemList ? res.item.examineSystemList.map(item => item.dickey) : [];
          this.formData.alarmbyvoice = res.item.alarmbyvoice
          this.formData.voiceduration = res.item.voiceduration
          this.formData.reportsmatter = res.item.reportsmatter
        }
      })
      this.modalVisible=true
    },
    confirm(){
      modifyMonitorPointSystemRole({...this.formData}).then(res=>{
        if(res&&res.returncode==='0'){
          this.modalVisible=false
          this.$message.success("配置成功")
        }
      })
    },
  }
}
</script>